<template>
  <div
    v-if="users.length > 0"
    class="avatar-group mt-2 d-flex align-items-center"
  >
    <router-link
      v-for="(user, index) in users.slice(0, maxUserCount)"
      :key="index"
      :to="`/app-users/${user.id}`"
      class="avatar rounded-circle"
      data-bs-toggle="tooltip"
      data-bs-placement="bottom"
      :aria-label="user.userName"
      :data-bs-original-title="user.userName"
    >
      <img
        :alt="user.userName"
        class="h-100"
        :src="getUserImage(user)"
      />
    </router-link>
    <span
      v-if="users.length > maxUserCount"
      class="ms-1"
    >+{{ users.length - maxUserCount }}</span>
  </div>
</template>

<script>
export default {
  props: {
    users: {
      type: Array,
      required: true
    },
    maxUserCount: {
      type: Number,
      default: 8
    }
  },

  methods: {
    getUserImage(user) {
      if (
        user.userImage == null ||
        user.userImage == ""
      ) return '/assets/img/placeholder_avatar.png';

      return user.userImage;
    }
  }
}
</script>

<style scoped>
a {
  width: 35px;
  height: 35px;
}

</style>